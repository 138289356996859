export default function() {
    var player;

    function onYouTubePlayerAPIReady() {
        player = new YT.Player('video', {
            events: {
                'onReady': onPlayerReady
            }
        });
    }

    function onPlayerReady(event) {

        var playButton = $('.button-play');//document.getElementById("button-play");
        playButton.on("click", setTimeout(function() {
            player.playVideo();
        }, 100));
    }

    $(function() {
        $(document).keyup(function(e) {
            if (e.key === "Escape" && $('.video_lightbox').hasClass('active')) { // escape key maps to keycode `27`
                stop_Video(e);
            }
        });

        $('.close_video').on('click', function(e){
            stop_Video(e);
        });

        function stop_Video(e){
            player.stopVideo();
            $('.video_lightbox').removeClass('active');
            $('.video_lightbox iframe').attr('src', "https://www.youtube.com/embed/")

        }
    });

    var tag = document.createElement('script');
    tag.src = "//www.youtube.com/player_api";
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
}