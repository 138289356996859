export default function() {

    var $burgerDesktop = $('.js-burger-toggle-desktop'),
        $burgerDesktopMenu = '.menu-liens-utiles-container',
        $burgerMobile = $('.js-burger-toggle-mobile'),
        $burgerMobileMenu = '.menu-menu-mobile-container';

    $burgerDesktop.on('click', function() {
        $(this).toggleClass('active');

        if ($(this).children($burgerDesktopMenu).hasClass('burger-in')) {
            $(this).children($burgerDesktopMenu).removeClass('burger-in').addClass('burger-out');
        }
        else if ($(this).children($burgerDesktopMenu).not('burger-in')) {
            $(this).children($burgerDesktopMenu).removeClass('burger-out').addClass('burger-in');
        }
    })

    $burgerMobile.on('click', function() {
        $(this).toggleClass('active');
        $('html').toggleClass('burger-active');

        if ($(this).children($burgerMobileMenu).hasClass('burger-in')) {
            $(this).children($burgerMobileMenu).removeClass('burger-in').addClass('burger-out');
        }
        else if ($(this).children($burgerMobileMenu).not('burger-in')) {
            $(this).children($burgerMobileMenu).removeClass('burger-out').addClass('burger-in');
        }
    })

    $('.menu').on('click', function(e) {
        e.stopPropagation();
    })

    $('#menu-menu-mobile .menu-item-has-children').on('click', function() {
        $(this).children('.sub-menu').slideToggle(300);
    })

    menuItemIn();

    $(window).on('resize', function() {
        menuItemIn();
    })

    setTimeout(function() {
        $('.menu-liens-utiles-container').css('display', 'block');
    }, 300)

    function menuItemIn() {
        if ($(window).width() > 1024) {
            $('.burger-icon li').each(function() {

                var i = $(this).index(),
                    delay = i * 100 + 500;

                $(this).css('transition-delay', delay + 'ms');
            })
        }
    }
}