export default function () {

    var $homeSlider = $('.js-slider-home'),
        $latestSlider = $('.js-slider-latest'),
        $secteurSanteSlider = $('.js-sante-slider'),
        $secteurAdministratifSlider = $('.js-administratif-slider'),
        $secteurAutresSlider = $('.js-autres-slider'),
        $secteurConstructionSlider = $('.js-construction-slider');

    $homeSlider.slick({
        slidesToShow: 1,
        slidesToScroll: 1,
        slide: '.js-slider-home-slide',
        dots: false,
        arrows: false,
    });

    $latestSlider.slick({
        slidesToShow: 3,
        slidesToScroll: 1,
        slide: '.js-slider-latest-slide',
        dots: false,
        arrows: true,
        prevArrow: '.js-latest-prev',
        nextArrow: '.js-latest-next',
        responsive: [
            {
                breakpoint: 1150,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                }
            }
        ]
    });

    $secteurSanteSlider.slick({
        slidesToShow: 3,
        dots: false,
        arrows: true,
        prevArrow: '.js-secteur-1-prev',
        nextArrow: '.js-secteur-1-next',
        slide: '.js-secteur-1-slide',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

    $secteurAdministratifSlider.slick({
        slidesToShow: 3,
        dots: false,
        arrows: true,
        prevArrow: '.js-secteur-2-prev',
        nextArrow: '.js-secteur-2-next',
        slide: '.js-secteur-2-slide',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

    $secteurAutresSlider.slick({
        slidesToShow: 3,
        dots: false,
        arrows: true,
        prevArrow: '.js-secteur-3-prev',
        nextArrow: '.js-secteur-3-next',
        slide: '.js-secteur-3-slide',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

    $secteurConstructionSlider.slick({
        slidesToShow: 3,
        dots: false,
        arrows: true,
        prevArrow: '.js-secteur-4-prev',
        nextArrow: '.js-secteur-4-next',
        slide: '.js-secteur-4-slide',
        responsive: [
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2,
                }
            }
        ]
    });

    pullSlider();

    $(window).on('resize', pullSlider);

    function pullSlider() {
        var containerInner = $('.container').width();
        var containerOuter = $('.container').outerWidth();
        var invisBox = $('.invisible-box').width();

        var gapA = (containerInner - invisBox) / 2;
        var gapB = (containerOuter - containerInner) / 2;

        var distance = gapA + gapB;

        $('.js-pull-slider').css('transform', 'translate('+ distance +'px, -40px)');
        if($(window).width() < 768){
            $('.js-pull-slider').css('transform', 'translate('+ distance +'px, -15px)');
        }
    }
}