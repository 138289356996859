export default function() {


    sarcaImage();
    soeBlueBox();

    $(window).on('resize', function() {
        sarcaImage();
        soeBlueBox();
    });

    function sarcaImage() {
        var halfWidth = $(window).width() / 2;
        $('.sarca__image').css('width', halfWidth + 'px');
    }

    function soeBlueBox() {
        var halfWidth = $(window).width() / 2;
        $('.blue-dark-box').css('width', halfWidth + 'px');
    }
}