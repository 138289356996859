export default function () {

	var index = 1;
	

	$('.album').each(function(){
		var target = '.album_' + index + ' a';
		$(target).simpleLightbox();
		index++;
	});
	

}