export default function () {

    if ($('#instafeed').length) {
        var feed = new Instafeed({
            target: instafeed,
            get: 'user',
            userId: '6201532444',
            limit: 4,
            sortBy: 'most-recent',
            accessToken: '6201532444.533046e.4a2deabb04de44988e1affa2134cc44b',
            resolution: 'standard_resolution',
            template: '<li style="background-image: url({{image}});"><a href="{{link}}" target="_blank"><span class="likes-count">{{likes}}</span><span class="comments-count">{{comments}}</span></a></li>',
        }).run();
    }
}