export default function () {
	jQuery('.js--menu--toggle').click(function(e) {
		e.preventDefault();
		jQuery(this).toggleClass('is-active');
		jQuery('body').toggleClass('menu-is-active');
	});

	jQuery('.mobile__menu .menu > li.menu-item-has-children > a').click(function(e) {
		e.preventDefault();
		jQuery('.mobile__menu .menu li .sub-menu').slideUp();
		jQuery(this).closest('li').find('.sub-menu').slideToggle();
	});
}