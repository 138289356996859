import start from './modules/router';
import scroller from './modules/scroller';
import search from './modules/search';
import mobileMenu from './modules/mobileMenu';
import underline from './modules/underline';
import slider from './modules/slider';
//import latest from './modules/latest';
import burger from './modules/burger';
import calculateModules from './modules/calculateModules';
import instafeed from './modules/instafeed';
import accordion from './modules/accordion';
import imageSize from './modules/imageSize';
import video from './modules/video';
import albumPhoto from './modules/albumPhoto';
import youtube from './modules/youtube';
import topLevelMenuLink from './modules/topLevelMenuLink';
import ambassadors from './modules/ambassadors';


scroller();
search();
mobileMenu();
underline();
slider();
burger();
calculateModules();
instafeed();
accordion();
imageSize();
video();
albumPhoto();
youtube();
topLevelMenuLink();
ambassadors();