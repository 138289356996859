export default function() {

    // Sums the program's modules duration
    var arrayHours = [];

    $('.js-module-duration').each(function() {
        arrayHours.push($(this).text());
    });

    var durationTotal = 0;

    for (var i = 0; i < arrayHours.length; i++) {
        durationTotal += arrayHours[i] << 0;
    }

    $('.js-hours-total').text(durationTotal);


    // Sums the program's units
    var arrayUnits = [];

    $('.js-module-units').each(function() {
        arrayUnits.push($(this).text());
    });

    var unitsTotal = 0;

    for (var i = 0; i < arrayUnits.length; i++) {
        unitsTotal += arrayUnits[i] << 0;
    }

    $('.js-units-total').text(unitsTotal);

}