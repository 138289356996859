export default function () {
	if($(".js-ambassador-grid").length) {
		var backgrounds = ["cyan", "orange", "marine", "marine", "cyan", "orange"];
		if ($(window).width() <= 1024) {
			backgrounds = ["cyan", "orange", "marine"];
		}else{
			backgrounds = ["cyan", "orange", "marine", "marine", "cyan", "orange"];
		}
	    var len = backgrounds.length;
	    var ambassadors = $(".js-ambassador");

	    bgColor();


	    $(".js-cta-filter").click(function(){
	    	if($(".js-filter").is(":hidden")){
	    		$(".js-filter").fadeIn();
	    		$(this).addClass("open");
	    	}
	    });

	    $(document).mouseup(function(e) {
		    var container = $(".js-filter");
		    if (!container.is(e.target) && container.has(e.target).length === 0) 
		    {
		        container.fadeOut();
		        $(".js-cta-filter").removeClass("open");
		    }
		});

	    $(".js-categ").click(function(){
	        var currentCat = $(this).attr('id');
	        $(".js-ambassador-grid").fadeOut(function(){
	        	$('.js-ambassador.open .ambassadors__grid-el-hover').hide();
		        ambassadors.removeClass('hidden open');
		        if(currentCat == "all"){
		        	ambassadors.each(function() {
		        		$(this).show();
		                $(this).addClass("visible");
		        	});
		        }else{
		            ambassadors.each(function() {
		                if($(this).hasClass(currentCat)){
		                    $(this).show();
		                    $(this).addClass("visible");
		                } 
		                else {
		                    $(this).hide();
		                    $(this).removeClass("visible");
		                }
		            });
		        }
		        bgColor();
		    });
	        $(".js-ambassador-grid").fadeIn();
	        $(".js-filter").fadeOut();
	        $(".js-cta-filter").removeClass("open");
	    });

	    $(".js-cta-open").click(function(){
	    	$(this).parent().parent().addClass('open');
	    	$(this).siblings(".ambassadors__grid-el-hover").fadeIn();
	    	$('.js-cta-open').not(this).parent().parent().addClass('hidden');
	    });

	    $(".js-cta-close").click(function(){
	    	$('.js-ambassador.open .ambassadors__grid-el-hover').fadeOut(function(){
	    		ambassadors.removeClass('open');
	    	});
	    	$(this).siblings(".ambassadors__grid-el-hover").fadeIn();
	    	ambassadors.removeClass('hidden');
	    });


	    function bgColor(){
	    	var elements = document.querySelectorAll(".ambassadors__grid-el.visible > div");
		    for (var i = 0; i < elements.length; i++) {
		        elements[i].className = '';
		        elements[i].className += backgrounds[i%len];
		    }
	    }
	}
}